<template>
  <span ref="elementTarget" class="paid-modal" @click="isDialog = true">
    <slot />
  </span>
  <teleport to="body">
    <Modal v-if="isDialog" v-model="isDialog" @update:modelValue="active = 0">
      <swiper
        class="swiper"
        :modules="modules"
        navigation
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }"
        :pagination="{ clickable: true }"
        :loop="true"
      >
        <swiper-slide v-for="(slide, i) in slides" :key="i">
          <img :src="url + slide" class="slide-img" />
        </swiper-slide>
      </swiper>
      <Flex class="mt-6" justify-center>
        <Button class="ml-2" width="140px" size="m" @click="openChatBot">
          お問い合わせ
        </Button>
      </Flex>
    </Modal>
  </teleport>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.css';
import Flex from '@/components/layout/Flex.vue';
import Modal from '@/components/ui/Modal.vue';
import Button from '@/components/ui/Button.vue';

export default defineComponent({
  name: 'PaidModal',
  components: {
    Modal,
    Flex,
    Button,
    Swiper,
    SwiperSlide
  },
  props: {},
  setup() {
    const isDialog = ref(false);
    let env = '';
    switch (window.location.hostname.split('.')[0]) {
      case 'tval':
        break;
      case 'stg.':
        env = 'stg';
        break;
      default:
        env = 'dev.';
        break;
    }
    const url = `https://file.${env}tval.jp/`;
    const slides = ['modal1.png', 'modal2.png', 'modal3.png'];

    const openChatBot = () => {
      window.ChannelIO('showMessenger');
    };

    return {
      isDialog,
      openChatBot,
      url,
      slides,
      modules: [Navigation, Pagination, Autoplay]
    };
  }
});
</script>

<style lang="scss" scoped>
$w: 752px;
$h: 370px;
.paid-modal {
  cursor: pointer;
}
.slide-img {
  width: $w;
  height: $h;
}
.swiper {
  width: $w !important;
  padding-bottom: 40px;

  ::deep {
    .swiper-wrapper {
      position: relative;
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 50%;
      height: 100%;
      top: 0;
      margin-top: 0 !important;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none;
    }
    .swiper-pagination-bullet-active {
      background: var(--primary-color);
    }
  }
}
</style>
