
import { defineComponent, PropType } from 'vue';
import { useRoute } from 'vue-router';
import Flex from '@/components/layout/Flex.vue';
import Box from '@/components/layout/Box.vue';
import Typography from '@/components/ui/Typography.vue';
import ListItem from '@/components/ui/ListItem.vue';
import Icon from '@/components/ui/Icon.vue';
import Tooltip from '@/components/ui/Tooltip.vue';
import MenuArrowSvg from '@/assets/images/menu-arrow.svg?inline';

type ChildrenMenu = {
  label: string;
  to: {
    name: string;
    params: { [key: string]: number | string };
  };
  icon: string | undefined;
  current: boolean;
  inactive: boolean | undefined;
  tooltip: boolean | undefined;
  hidden: boolean;
  lock: boolean;
};

export default defineComponent({
  name: 'SidebarPopupChildrenMenus',
  components: {
    Flex,
    Box,
    Typography,
    ListItem,
    Icon,
    Tooltip,
    MenuArrowSvg
  },
  props: {
    title: String,
    menuChildrens: Array as PropType<ChildrenMenu[]>
  },
  setup() {
    const route = useRoute();
    return {
      route
    };
  }
});
