<template>
  <nav aria-label="Breadcrumb" class="breadcrumb">
    <ul>
      <template v-for="parent in parents" :key="parent.label">
        <li>
          <router-link
            v-if="parent.name"
            :to="{
              name: parent.name,
              params: parent.params,
              query: parent.query
            }"
          >
            <Typography nowrap color="link" size="s" line-height="1.5">
              {{ parent.label }}
            </Typography>
          </router-link>
          <Typography nowrap v-else size="s" color="inherit" line-height="1.5">
            {{ parent.label }}
          </Typography>
        </li>
        <li class="mx-1">
          <Icon name="arrow-right" sides="8" color="inherit" class="icon" />
        </li>
      </template>
      <li>
        <TypographyEllipsis
          aria-current="page"
          size="s"
          color="inherit"
          line-height="1.5"
        >
          {{ current.label }}
        </TypographyEllipsis>
      </li>
    </ul>
    <slot name="breadcrumb-content" />
  </nav>
</template>

<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography.vue';
import TypographyEllipsis from '@/components/ui/TypographyEllipsis.vue';
import Icon from '@/components/ui/Icon.vue';

export default defineComponent({
  name: 'Breadscrumbs',
  components: { Typography, TypographyEllipsis, Icon },
  props: ['parents', 'current'],
  setup() {
    return {};
  }
});
</script>

<style lang="scss">
.breadcrumb {
  display: flex;
  justify-content: space-between;
  height: 24px;
}
.breadcrumb ul {
  display: flex;
  color: var(--grey-text-color);
  height: 12px;
}

.breadcrumb li {
  .ui-color {
    color: var(--grey-text-color);
  }
  height: 12px;
}

.breadcrumb .icon {
  position: relative;
  top: -5px;
  line-height: 1.8;
}
</style>
