<template>
  <div
    v-for="(menu, index) in menus"
    :key="`menu-${index}`"
    class="sidebar-menus"
  >
    <template v-if="menu.to && !menu.hidden">
      <template
        v-if="
          isLockedMenu(menu.to.name) ||
            (isLockMenuRestricted && !menu.defaultUnLock) ||
            menu.lock
        "
      >
        <template v-if="isLockMenuRestricted || menu.lock">
          <ListItem :current="menu.current">
            <Flex justify-space-between align-center>
              <Flex align-center>
                <Icon :name="menu.icon" color="primary" class="mr-2" />
                <Typography
                  :color="menu.current ? 'primary' : 'text'"
                  line-height="1"
                >
                  {{ menu.label }}
                </Typography>
              </Flex>
              <Icon name="lock" sides="12" color="disabled" />
            </Flex>
          </ListItem>
        </template>

        <template v-else>
          <PaidModal v-if="menu.to.name !== 'projectList'">
            <ListItem :current="menu.current">
              <Flex justify-space-between align-center>
                <Flex align-center>
                  <Icon :name="menu.icon" color="primary" class="mr-2" />
                  <Typography
                    :color="menu.current ? 'primary' : 'text'"
                    line-height="1"
                  >
                    {{ menu.label }}
                  </Typography>
                </Flex>
                <Icon name="lock" sides="12" color="disabled" />
              </Flex>
            </ListItem>
          </PaidModal>

          <router-link v-else :to="{ name: 'freeLp' }">
            <ListItem :current="menu.current">
              <Flex justify-space-between align-center>
                <Flex align-center>
                  <Icon :name="menu.icon" color="primary" class="mr-2" />
                  <Typography
                    :color="menu.current ? 'primary' : 'text'"
                    line-height="1"
                  >
                    {{ menu.label }}
                  </Typography>
                </Flex>
                <Icon name="lock" sides="12" color="disabled" />
              </Flex>
            </ListItem>
          </router-link>
        </template>
      </template>
      <template v-else>
        <div v-if="menu.children">
          <div v-bind:class="sidebarChildrenClassRef">
            <div>
              <ListItem @click="onClickToggleMenu(index)">
                <Flex justify-space-between align-center>
                  <Flex align-center>
                    <Icon :name="menu.icon" color="primary" class="mr-2" />
                    <Typography color="text" line-height="1">
                      {{ menu.label }}
                    </Typography>
                  </Flex>
                  <Icon
                    name="caret-down"
                    sides="12"
                    color="primary"
                    :class="
                      menuStatus[index]
                        ? 'slidebarMenu-icon slidebarMenu-icon--active'
                        : 'slidebarMenu-icon'
                    "
                  />
                </Flex>
              </ListItem>
            </div>
            <div class="sidebar-children-menu" v-if="showSidebarRef">
              <transition @enter="enter" @leave="leave">
                <Box v-show="menuStatus[index]" marginLeft="27px">
                  <template
                    v-for="children in menu.children"
                    :key="children.label"
                  >
                    <template v-if="!children.inactive">
                      <router-link
                        v-if="!children.hidden"
                        :to="{ ...children.to }"
                      >
                        <ListItem
                          :current="children.current"
                          class="children-item"
                        >
                          <Flex justify-space-between align-center>
                            <Flex align-center>
                              <Icon
                                v-if="children.icon"
                                :name="children.icon"
                                color="primary"
                                class="mr-2"
                              />
                              <Typography
                                :color="children.current ? 'primary' : 'text'"
                                line-height="1"
                              >
                                {{ children.label }}
                              </Typography>
                            </Flex>
                          </Flex>
                        </ListItem>
                      </router-link>
                    </template>
                    <template v-else>
                      <ListItem inactive class="children-item">
                        <Tooltip
                          :content="children.tooltip"
                          style="cursor: default"
                        >
                          <Flex justify-space-between align-center>
                            <Flex align-center>
                              <Icon
                                v-if="children.icon"
                                :name="children.icon"
                                color="primary"
                                class="mr-2"
                              />
                              <Typography color="grey" line-height="1">
                                {{ children.label }}
                              </Typography>
                            </Flex>
                            <Icon color="disabled" name="lock" sides="12" />
                          </Flex>
                        </Tooltip>
                      </ListItem>
                    </template>
                  </template>
                </Box>
              </transition>
            </div>
            <SidebarPopupChildrenMenus
              v-else
              :title="menu.label"
              :menu-childrens="menu.children"
            />
          </div>
          <!-- 親子メニュー -->
        </div>
        <template v-else-if="!showSidebarRef">
          <router-link :to="{ ...menu.to }">
            <Tooltip :content="menu.label" placement="right" :delay-show="500">
              <ListItem :current="menu.current">
                <Flex justify-space-between align-center>
                  <Flex align-center>
                    <Icon :name="menu.icon" color="primary" class="mr-2" />
                    <Typography
                      :color="menu.current ? 'primary' : 'text'"
                      line-height="1"
                    >
                      {{ menu.label }}
                    </Typography>
                  </Flex>
                  <Icon
                    v-if="menu.free"
                    name="lock"
                    sides="12"
                    color="disabled"
                  />
                </Flex>
              </ListItem>
            </Tooltip>
          </router-link>
        </template>
        <template v-else>
          <router-link :to="{ ...menu.to }">
            <ListItem :current="menu.current">
              <Flex justify-space-between align-center>
                <Flex align-center>
                  <Icon :name="menu.icon" color="primary" class="mr-2" />
                  <Typography
                    :color="menu.current ? 'primary' : 'text'"
                    line-height="1"
                  >
                    {{ menu.label }}
                  </Typography>
                </Flex>
                <Icon
                  v-if="menu.free"
                  name="lock"
                  sides="12"
                  color="disabled"
                />
              </Flex>
            </ListItem>
          </router-link>
        </template>
      </template>
    </template>
    <template v-else-if="!menu.hidden">
      <ListItem inactive>
        <Tooltip content="今後リリース予定の<br />機能です">
          <Flex justify-space-between align-center>
            <Flex align-center>
              <Icon :name="menu.icon" color="disabled" class="mr-2" />
              <Typography line-height="1" color="grey">
                {{ menu.label }}
              </Typography>
            </Flex>
            <Icon
              v-if="false && menu.free"
              color="disabled"
              name="lock"
              sides="12"
            />
          </Flex>
        </Tooltip>
      </ListItem>
    </template>
  </div>
</template>
<script>
import { ref, defineComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import Flex from '@/components/layout/Flex.vue';
import Box from '@/components/layout/Box.vue';
import Icon from '@/components/ui/Icon.vue';
import ListItem from '@/components/ui/ListItem.vue';
import Tooltip from '@/components/ui/Tooltip.vue';
import Typography from '@/components/ui/Typography.vue';
import { useUserInfoStore } from '@/store/userInfo';
import {
  JoinWorkspaceAccountPrivilegeEnum,
  JoinWorkspacePaymentTypeEnum
} from '@/api/openapi';
import {
  CAMPAIGN_ROUTES,
  CUSTOM_ROUTES,
  PLANNING_ROUTES,
  DATASEARCH_ROUTES
} from '@/router';
import PaidModal from '@/components/ui/PaidModal.vue';
import SidebarPopupChildrenMenus from './SidebarPopupChildrenMenus.vue';
import { useSidebarStore } from '@/store/sidebar';

const LOCK_ROUTE_NAMES_FOR_FREE_WORKSPACE = [
  CAMPAIGN_ROUTES.index,
  PLANNING_ROUTES.index,
  DATASEARCH_ROUTES.index,
  CUSTOM_ROUTES.index
];

export default defineComponent({
  name: 'SidebarMenus',
  components: {
    Flex,
    Box,
    Icon,
    ListItem,
    Tooltip,
    Typography,
    PaidModal,
    SidebarPopupChildrenMenus
  },
  props: {
    /**
     * {
     *   label: string;
     *   icon: string;
     *   free: boolean; // 無料WS
     *   routeName: string;
     * }[]
     */
    menus: Array,
    parent: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const route = useRoute();
    const userInfoStore = useUserInfoStore();
    const menuStatus = ref(
      props.menus.map(menu => {
        return menu.current;
      })
    );

    const currentWorkspace = userInfoStore.currentWorkspaceFromRoute(route);

    const isLockedMenu = routeName =>
      currentWorkspace.paymentType === JoinWorkspacePaymentTypeEnum.Free &&
      LOCK_ROUTE_NAMES_FOR_FREE_WORKSPACE.includes(routeName);

    const isLockMenuRestricted =
      currentWorkspace.accountPrivilege ===
      JoinWorkspaceAccountPrivilegeEnum.Restricted;

    const isPaidWorkspace =
      currentWorkspace?.paymentType === JoinWorkspacePaymentTypeEnum.Paid;

    // @event 開閉メニュー
    const onClickToggleMenu = index => {
      if (showSidebarRef.value) {
        menuStatus.value[index] = !menuStatus.value[index];
      }
    };

    // @event transtion
    const enter = element => {
      element.style.height = 'auto';
      const height = getComputedStyle(element).height;
      element.style.height = 0;
      getComputedStyle(element);
      setTimeout(() => {
        element.style.height = height;
      });
    };

    const leave = element => {
      element.style.height = getComputedStyle(element).height;
      getComputedStyle(element);
      setTimeout(() => {
        element.style.height = 0;
      });
    };

    const sidebarStore = useSidebarStore();
    const showSidebarRef = ref(false);
    const sidebarChildrenClassRef = ref(
      !sidebarStore.getShowSidebar ? 'sidebar-children-hover' : ''
    );
    watch(
      () => sidebarStore.getShowSidebar,
      getShowSidebar => {
        showSidebarRef.value = getShowSidebar;
        sidebarChildrenClassRef.value = !getShowSidebar
          ? 'sidebar-children-hover'
          : '';
      },
      {
        immediate: true
      }
    );

    return {
      route,
      isLockedMenu,
      isLockMenuRestricted,
      isPaidWorkspace,
      menuStatus,
      showSidebarRef,
      sidebarChildrenClassRef,
      onClickToggleMenu,
      enter,
      leave
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/layout';
@import '@/styles/spacing';

.sidebar-menus {
  width: $side-menu-width;
  overflow: hidden;
  &:not(:first-child) {
    margin-top: $spacing-base;
  }

  &:empty {
    margin-top: 0;
  }

  &.lock {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-isRestricted {
    cursor: default;

    &:hover {
      background-color: #fff;
    }
  }
}

// トランジション_アコーディオンメニュー
.v-enter-active,
.v-leave-active {
  transition: height 0.3s;
}

// アイコン
.slidebarMenu-icon {
  transition: transform 0.2s ease;
}
.slidebarMenu-icon--active {
  transform: rotate(180deg);
}

// メニュー
.ui-typography {
  &.children-item {
    padding: 8px 12px;
    &.current {
      border-radius: 4px 0 0 4px;
    }
    &:hover {
      border-radius: 4px 0 0 4px;
    }
  }
}
</style>
